<template>
  <div>
    <h4 class="card-label border-bottom mb-3">Menu Items</h4>
    <div class="loader-wrapper" v-if="loader">
      <div class="image-loader"></div>
    </div>
    <div class="form-group">
      <div class="card-body">
        <div class="row">
          <div class="text-center">
            <b-button
              @click="addMenuItem"
              class="btn custom-btn-style"
              v-b-modal.modal-standard
              variant=":class"
            >
              Add Menu Item
            </b-button>
            <b-modal
              id="modal-standard"
              :title="menu_item_title"
              title-class="font-18"
              ref="modal"
              @show="resetModal"
              @hidden="resetModal"
              @ok="handleOk"
            >
              <form ref="form" @submit.stop.prevent="handleSubmit">
                <div class="form-group">
                  <label>Select Menu</label>
                  <div>
                    <select
                      v-model="menu_id"
                      required
                      class="form-control-role"
                    >
                      <option disabled value="">Select Menu</option>
                      <option
                        v-for="menu in menus"
                        :value="menu.id"
                        v-bind:key="menu.id"
                        :title="menu.menu_category_name"
                      >
                        {{ menu.menu_category_name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <label>Select Menu Item Types</label>
                  <div>
                    <select
                      v-model="menu_item_type_id"
                      required
                      class="form-control-role"
                    >
                      <option disabled value="">Select Menu Item Types</option>
                      <option
                        v-for="item_type in menu_item_types"
                        :value="item_type.id"
                        v-bind:key="item_type.id"
                        :title="item_type.menu_item_type"
                      >
                        {{ item_type.menu_item_type }}
                      </option>
                    </select>
                  </div>
                </div>
                <b-form-group
                  label="Item Name"
                  label-for="name-input"
                  invalid-feedback="Name is required"
                >
                  <b-form-input
                    id=""
                    v-model="item_name"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Price"
                  label-for="name-input"
                  invalid-feedback="Price is required"
                >
                  <b-form-input
                    type="number"
                    id=""
                    v-model="item_price"
                    min="1"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Quantity"
                  label-for="name-input"
                  invalid-feedback="Quantity is required"
                >
                  <b-form-input
                    type="number"
                    id=""
                    v-model="item_quantity"
                    min="1"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Stock"
                  label-for="name-input"
                  invalid-feedback="Stock is required"
                >
                  <b-form-input
                    type="number"
                    id=""
                    min="1"
                    v-model="daily_stock"
                    required
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Topping Limit"
                  label-for="name-input"
                  invalid-feedback="Topping Limit is required"
                >
                  <b-form-input
                    type="number"
                    id=""
                    min="1"
                    v-model="topping_limit"
                    required
                  ></b-form-input>
                </b-form-group>
                <div>
                  <b-form-group label="Is Generic">
                    <b-form-radio
                      v-model="is_generic"
                      name="is_generic"
                      value="1"
                      >Yes
                    </b-form-radio>
                    <b-form-radio
                      v-model="is_generic"
                      name="is_generic"
                      value="0"
                      >No
                    </b-form-radio>
                  </b-form-group>
                </div>
                <div>
                  <b-form-group
                    label="File"
                    label-for="document-input"
                    invalid-feedback="Menu Item image is required"
                  >
                    <b-form-file
                      id="document-input"
                      v-model="item_image"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      accept=".png, .jpg, .jpeg"
                      @change="onFileChange"
                    ></b-form-file>
                  </b-form-group>
                </div>
                <b-form-group label="Description" label-for="comment-input">
                  <b-form-textarea
                    id="description-input"
                    v-model="description"
                  ></b-form-textarea>
                </b-form-group>
              </form>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <div class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Show
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
            ></b-form-select>
            entries
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-6">
        <div class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            Search:
            <b-form-input
              v-model="filter"
              type="search"
              placeholder="Search..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
          </label>
          <button class="btn print excel-btn" @click="excelExport()">
            Export to Excel
          </button>
          <button class="btn print" @click="csvExport(csvData)">
            Export to CSV
          </button>
        </div>
      </div>
    </div>
    <!-- End search -->
    <div class="card">
      <div class="card-body p-0">
        <div class="row mb-md-2"></div>
        <!-- Table -->
        <div class="table-responsive mb-0">
          <b-table
            :items="menu_items"
            :fields="menu_extra_type_fields"
            responsive="true"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:cell(src)="menu_items">
              <img
                v-if="menu_items.item.src !== null"
                :src="menu_items.item.src"
                style="width: 50px; height: 50px"
                @error="imageLoaderError"
              />
              <img
                v-else
                :src="'icontest.png'"
                style="width: 50px; height: 50px"
              />
            </template>
            <template v-slot:cell(action)="menu_items">
              <div class="position-relative">
                <div class="table-actions">
                  <a
                    href="javascript:void(0)"
                    class="success-icon"
                    @click="editModal(menu_items)"
                    v-b-modal.modal-standard
                  >
                    <i class="fas fa-pencil-alt"></i>
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="reject-icon"
                    @click="deleteMenuItem(menu_items)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </div>
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import XLSX from "xlsx";
import axios from "axios";
import helpers from "../../helpers";

export default {
  name: "MenuItem",
  data() {
    return {
      loader: false,
      search: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: true,
      DropDownMenu: false,
      itemUUID: "",
      restaurantUuid: JSON.parse(localStorage.getItem("restaurantDetails"))
        .uuid,
      menus: [],
      menu_item_types: [],
      menu_items: [],
      menu_extra_type_fields: [
        { key: "item_name", label: "Item Name", sortable: true },
        { key: "src", label: "Item Image", sortable: true },
        { key: "menu_name", label: "Menu Name", sortable: true },
        { key: "item_price", label: "Price", sortable: true },
        { key: "item_quantity", label: "Quantity", sortable: true },
        { key: "action" }
      ],
      menu_item_id: "",
      menu_id: "",
      menu_item_type_id: "",
      item_name: "",
      item_image: null,
      item_price: "",
      item_quantity: "",
      daily_stock: "",
      description: "",
      is_generic: "",
      topping_limit: "",
      menu_item_title: "",
      is_edit: false
    };
  },
  components: {},
  computed: {
    rows() {
      return this.menu_items.length;
    },
    csvData() {
      return this.menu_items.map(item => ({
        ...item
      }));
    }
  },
  methods: {
    imageLoaderError(event) {
      return (event.target.src = "icontest.png");
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.menuItemState = valid;
      return valid;
    },
    addMenuItem() {
      this.menu_item_title = "Add Menu Item";
      this.is_edit = false;
      this.menu_id = "";
      this.menu_item_type_id = "";
      this.item_name = "";
      this.item_image = "";
      this.item_price = "";
      this.item_quantity = "";
      this.daily_stock = "";
      this.description = "";
      this.is_generic = "";
      this.topping_limit = "";
    },
    resetModal() {},
    editModal(menu_item) {
      this.menu_item_id = menu_item.item.id;
      this.menu_id = menu_item.item.menu_id;
      this.menu_item_type_id = menu_item.item.menu_item_type_id;
      this.item_name = menu_item.item.item_name;
      this.item_image = menu_item.item.item_image;
      this.item_price = menu_item.item.item_price;
      this.item_quantity = menu_item.item.item_quantity;
      this.daily_stock = menu_item.item.daily_stock;
      this.description = menu_item.item.description;
      this.is_generic = menu_item.item.is_generic;
      this.topping_limit = menu_item.item.topping_limit;
      this.is_edit = true;
      this.menu_item_title = "Edit Menu Item";
    },
    handleOk(bvModalEvt) {
      this.loader = true;
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
      let URL = "";
      let toastMessage = "";

      let formData = new FormData();
      if (this.is_edit) {
        URL = this.$path + "/admin/update/menu/items";
        toastMessage = "Menu Item Updated!";
        formData.append("menu_item_id", this.menu_item_id);
      } else {
        URL = this.$path + "/admin/add/menu/items";
        toastMessage = "Menu Item Added!";
      }
      formData.append("menu_id", this.menu_id);
      formData.append("menu_item_type_id", this.menu_item_type_id);
      formData.append("item_name", this.item_name);
      formData.append("item_image", this.item_image);
      formData.append("item_price", this.item_price);
      formData.append("item_quantity", this.item_quantity);
      formData.append("daily_stock", this.daily_stock);
      formData.append("description", this.description);
      formData.append("is_generic", this.is_generic);
      formData.append("topping_limit", this.topping_limit);
      ApiService.post(URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(() => {
          this.$bvToast.toast(toastMessage, {
            title: "Success",
            variant: "success",
            solid: true
          });
          this.is_edit = false;
          this.getMenuItems();
          this.loader = false;
        })
        .catch(err => {
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-standard");
      });
    },
    getMenuItems() {
      this.loader = true;
      axios
        .get(this.$path + "/admin/menu/items/" + this.restaurantUuid)
        .then(({ data }) => {
          this.menu_items = data.data;
          this.loader = false;
        })
        .catch(err => {
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    getMenu() {
      axios
        .get(
          this.$path + "/admin/restaurant/menu/category/" + this.restaurantUuid
        )
        .then(({ data }) => {
          this.menus = data.data;
        })
        .catch(err => {
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    getMenuItemType() {
      axios
        .get(this.$path + "/admin/menu/item/types")
        .then(({ data }) => {
          this.menu_item_types = data.data;
        })
        .catch(err => {
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    deleteMenuItem(menu_item) {
      this.loader = true;
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          axios
            .get(this.$path + "/admin/delete/menu/items/" + menu_item.item.uuid)
            .then(() => {
              this.$bvToast.toast("Menu Item Delete Successfully!", {
                title: "Success",
                variant: "success",
                solid: true
              });
              this.getMenuItems();
              this.loader = false;
            })
            .catch(err => {
              if (err.response.data.status === 400) {
                helpers.errorOther(this, err.response.data);
              }
              if (err.response.data.status === 422) {
                helpers.validationError(this, err.response.data.errors);
              }
              if (err.response.status === 404) {
                helpers.errorMessage(this, "url not found");
              }
              if (err.response.status === 500) {
                helpers.serverError(this);
              }
            });
        }
        this.loader = false;
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clickDots(uuid) {
      this.itemUUID = uuid;
      if (this.DropDownMenu) {
        this.DropDownMenu = false;
      } else {
        this.DropDownMenu = true;
      }
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "MenuItem.csv");
      link.click();
    },
    excelExport() {
      const data = XLSX.utils.json_to_sheet(this.menu_items);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "MenuItem.xlsx");
    },
    closeMenu() {
      this.DropDownMenu = false;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.closeMenu();
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.item_image = file;
    }
  },
  mounted() {
    document.addEventListener("click", this.close);
    this.getMenuItems();
    this.getMenu();
    this.getMenuItemType();
    this.totalRows = this.menu_items.length;
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  }
};
</script>

<style scoped></style>
